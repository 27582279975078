.wrapper {
    font-weight: normal;
    text-align: center;
    padding: 15px 20px;
    background-color: var(--col--dark);
    color: var(--col--light);
}

.link {
    text-decoration: underline;
    font-weight: bold;
}

@media (min-width: 961px) {
    .wrapper {
        display: none;
    }
}
